import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ArrowRight from "../components/svg/arrowRight"
import Direction from "../components/svg/direction"
import Wall from "../components/svg/wall"
import ChartMan from "../components/svg/chartMan"
import Exist from "../components/svg/exist"
import Arrow from "../components/svg/arrow"
import CurveTop from "../components/svg/curveTop"
import ClientLogos from "../components/clientLogos"
import Quotes from "../components/svg/quotes"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const IndexPage = ({data}) => (

  	<Layout>

      <Seo 
         title="Media Consultancy"
         description="Madclarity offers unique media consultancy services, including media benchmarking, to marketers in Australia to improve media effectiveness, performance and value."
      />

      <section role="main" className="hero">
         <div className="container">
            <div className="row">
               <div className="hero-content home-hero">
                  <div className="home-hero-text">
                     <h1
                        data-sal="slide-up" 
                        data-sal-delay="200"
                        data-sal-duration="500"
                     >
                        Marketers spend millions on advertising <span className="blue">hoping</span> to get great value
                     </h1>
                     <p
                        data-sal="slide-up" 
                        data-sal-delay="400"
                        data-sal-duration="500"
                     >We find ways to improve the media and advertising performance of every
                     single organisation that engages us. Frank and fearless advice. Proprietary
                     software systems and hard-nosed data analysis.</p>
                     <div className="btn-row"
                        data-sal="slide-up" 
                        data-sal-delay="600"
                        data-sal-duration="500"
                     >
                        <Link to="/contact" className="btn btn-lg">Contact us</Link>
                        <Link to="/how-we-help" className="btn-link">
                           How we help
                           <ArrowRight />
                        </Link>
                     </div>
                  </div>
                  <div className="home-hero-img">
                     <Direction />
                  </div>
               </div>
            </div>
         </div>
      </section>

      <div className="curve-top">
         <CurveTop />
      </div>
      <section className="bg client-section">
         <div className="container">
            <div className="row">
                 <div className="client-title">Some of our clients ...</div> 
                 <ClientLogos />
            </div>
         </div>
      </section>
      <div className="curve-btm">
         <CurveTop />
      </div>

      <section className="section-pad">
         <div className="container">
            <div className="row">
               <div className="col-middle-wrapper">
                  <div className="col-middle col-middle-sm">
                     <h2
                        className="h2-lg" 
                        data-sal="slide-up" 
                        data-sal-delay="200"
                        data-sal-duration="500"
                     >
                        We are <span className="blue">Madclarity</span>
                     </h2>
                     <p className="lead"
                        data-sal="slide-up" 
                        data-sal-delay="400"
                        data-sal-duration="500"
                     
                     >We create media &amp; advertising clarity from complexity.</p>
                  </div> 
               </div>
               <div className="col-three-wrapper mt-2">
                  <Link to="/how-we-help" className="col-three and-center text-center widget-link"
                     data-sal="fade" 
                     data-sal-delay="400"
                     data-sal-duration="500"
                  >
                     <div className="col-three-img">
                        <Wall />
                     </div>
                     <h3 className="h3-lg">How we <span className="blue">help</span></h3>
                     <Arrow />
                  </Link>

                  <Link to="/what-we-do" className="col-three and-center text-center mid-col-margin widget-link"
                     data-sal="fade" 
                     data-sal-delay="600"
                     data-sal-duration="500"
                  >
                     <div className="col-three-img">
                        <Exist />
                     </div>
                     <h3 className="h3-lg">What we <span className="blue">do</span></h3>
                     <Arrow />
                  </Link>

                  <Link to="/why-we-exist" className="col-three and-center text-center widget-link"
                     data-sal="fade" 
                     data-sal-delay="800"
                     data-sal-duration="500"
                  >
                     <div className="col-three-img">
                        <ChartMan />
                     </div>
                     <h3 className="h3-lg">Why we <span className="blue">exist</span></h3>
                     <Arrow />
                  </Link>
               </div>
            </div>
         </div>
      </section>

      <div className="curve-top flip">
         <CurveTop />
      </div>
      <section className="bg testimonials">
         <div className="container">
            <div className="row">
               <div className="testimonial-col-wrapper">
                  <div className="testimonial-col"
                     data-sal="fade" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                  >
                     <Quotes />
                     <div className="test-excerpt">
                        ... brutally honest,
                        <span className="blue"> independent advice</span> ...
                     </div>
                     <Testimonial
                        quote="I rely on Madclarity to provide external media benchmarking and brutally honest, independent advice on how to transform the performance of our single biggest marketing investment.   I’ve worked with them at PepsiCo, McDonald’s and Arnott’s and they have always delivered."
                        name="Jenni Dill"
                        title="Chief Marketing Officer, The Arnott’s Group"
                     />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <div className="curve-btm flip">
         <CurveTop />
      </div>

      <Cta />

  </Layout>
)

export default IndexPage