import React from "react"

const Arrow = () => (
    <svg width="53" height="51" viewBox="0 0 53 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M51.8601 25.282L34.265 35.8099L34.3814 30.2359L6.75369 31.3195L7.06304 17.9035L34.5961 20.3932L34.7156 15.2098L51.8601 25.282Z" fill="#61D0EC"/>
        <path d="M34.5959 20.393L7.06286 17.9033" stroke="#272223" strokeWidth="1.21995" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M34.381 30.2357L6.75335 31.3192L7.0627 17.9033" stroke="#272223" strokeWidth="1.21995" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M34.3811 30.2356L34.2647 35.8096L51.8598 25.2817L34.7154 15.2095L34.5958 20.3929" stroke="#272223" strokeWidth="1.21995" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.0961 28.77C18.6091 28.3832 24.5123 27.9824 31.0253 27.5957" stroke="#272223" strokeWidth="0.609976" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M46.0871 25.3194C43.3608 23.9508 40.6825 22.62 37.9562 21.2514" stroke="#272223" strokeWidth="0.609976" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M41.6451 28.4995C40.4421 29.3382 39.3248 30.1666 38.1217 31.0053" stroke="#272223" strokeWidth="0.609976" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.5219 20.3246C15.2217 20.7516 18.3014 21.0789 22.0012 21.5059" stroke="#272223" strokeWidth="0.609976" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Arrow